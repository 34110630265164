// 课程管理的路由
import Vue from 'vue';
import Router from 'vue-router'

Vue.use(Router);

export default [
    {
        path: "/coursManage",
        name: "coursManage",
        component: () =>
            import(/* webpackChunkName: "roleManage" */ "@/views/Home.vue"),
        redirect: "/coursManage/courseList",
        meta: {title: "课程管理"},
        children: [
            {
                path: "/coursManage/courseList", // 课程列表
                name: "courseList",
                meta: {title: "课程列表", isShow: true},
                component: () =>
                    import(
                        /* webpackChunkName: "courseList" */ "@/views/coursManage/CourseList.vue"
                        ),
                children: [
                    {
                        path: "/coursManage/courseList/addSourse/:id", // 新建/编辑课程
                        name: "addSourse",
                        meta: {title: "新建/编辑课程"},
                        component: () =>
                            import(
                                /* webpackChunkName: "addSourse" */ "@/views/coursManage/AddSourse.vue"
                                ),
                    },
                    {
                        path: "/coursManage/courseList/newAttr/:id", // 新建/编辑课程
                        name: "newAttr",
                        meta: {title: "新建/编辑课程"},
                        component: () =>
                            import(
                                /* webpackChunkName: "addSourse" */ "@/views/coursManage/NewAttr.vue"
                                ),
                    },
                    {
                        path: "/coursManage/courseList/contentManage", // 内容管理
                        name: "contentManage",
                        meta: {title: "内容管理", isShow: true},
                        component: () =>
                            import(
                                /* webpackChunkName: "contentManage" */ "@/views/coursManage/ContentManage.vue"
                                ),
                        children: [
                            {
                                path:
                                    "/coursManage/courseList/contentManage/addLessons/:id/:courseNo/:catalogueFlag/", // 添加/修改课节
                                name: "addLessons",
                                meta: {title: "新建/编辑课节", isShow: true},
                                component: () =>
                                    import(
                                        /* webpackChunkName: "addLessons" */ "@/views/coursManage/AddLessons.vue"
                                        ),
                            },
                        ],
                    },
                    {
                        path: '/coursManage/courseList/relationShip',  // 人物关系图路由
                        name: 'relationShip',
                        meta: {title: '人物关系图', isShow: true},
                        component: () => import(/* webpackChunkName: "relationShip" */ '@/views/coursManage/RelationShip.vue'),
                        children: [
                            {
                                path: "/coursManage/courseList/relationShip/atlas/:peopleNo", // 添加/修改人物关系图
                                name: "atlas",
                                meta: {title: "新建/编辑图谱", isShow: true},
                                component: () =>
                                    import(
                                        /* webpackChunkName: "atlas" */ "@/views/coursManage/Atlas.vue"
                                        ),
                            },
                        ],
                    },
                    {
                        path: "/coursManage/courseList/posterManage/:id/", // 海报管理
                        name: "posterManage",
                        meta: {title: "海报管理"},
                        component: () => import(/* webpackChunkName: "PosterManage" */ "@/views/coursManage/PosterManage.vue"),
                  },
                  {
                    path: "/coursManage/courseList/studyPlan/:courseNo", // 学习规划
                    name: "StudyPlan",
                    meta: {title: "学习规划", secondRoute: true},
                    component: () => import("@/views/coursManage/studyPlan/index.vue"),
                    children: [
                      {
                        path: "/coursManage/courseList/studyPlan/labelList/:courseNo/:stageNo", // 
                        name: "StudyPlanLabel",
                        meta: {title: "标签列表"},
                        component: () => import("@/views/coursManage/studyPlan/labelList.vue"),
                        children: [{
                          path: "/coursManage/courseList/studyPlan/content/:courseNo/:stageNo/:tagNo", // 
                          name: "StudyCourseContent",
                          meta: {title: "课程列表"},
                          component: () => import("@/views/coursManage/studyPlan/CourseContent.vue"),
                        }]
                      }
                    ]
                  },
                ],
            },
            {
                path: "/coursManage/courseTag", // 课程标签
                name: "courseTag",
                meta: {title: "课程标签"},
                component: () =>
                    import(
                        /* webpackChunkName: "CourseTag" */ "@/views/coursManage/CourseTag.vue"
                        ),
            },
            {
                path: "/coursManage/courseCategory", // 课程分类
                name: "courseCategory",
                meta: {title: "课程分类", isShow: true},
                component: () =>
                    import(
                        /* webpackChunkName: "courseCategory" */ "@/views/coursManage/CourseCategory.vue"
                        ),
                children: [
                    {
                        path: "/coursManage/courseCategory/addCategory/:id", // 新建/编辑分类
                        name: "addCategory",
                        meta: {title: "新建/编辑分类", isShow: true},
                        component: () => import("@/views/coursManage/AddCategory.vue"),
                    },
                ],
            },
            {
                path: "/coursManage/classSection", // 课节分类
                name: "classSection",
                meta: {title: "课节分类", isShow: true},
                component: () => import(/* webpackChunkName: "courseCategory" */ "@/views/coursManage/classSection/ClassClassification.vue"),
          },
        ],
    },
];
